<template>
  <div>
    <message />
  </div>
</template>

<script>
import message from "../components/Message.vue";
export default {
  components: {
    message
  }
};
</script>

<style>
</style>