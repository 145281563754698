<template>
  <div>
    <div class="container0a">
      <div class="left">
        <div>
          <h3>消息内容</h3>
          <h4 class="title0a" v-if="currentRow.title">
            {{ currentRow.title }}
            <el-button type="text" size="mini" round @click="deleteThis">删除</el-button>
          </h4>
          <div class="cellscreen0a">
            <pre>{{ currentRow.content }}</pre>
            <img
              v-if="currentRow.img"
              :src="'https://server.solar960.com' + currentRow.img"
              alt
              width="100%"
              style="margin-top: 15px"
            />
          </div>
        </div>
      </div>
      <div class="right">
        <h3>消息列表</h3>
        <h4 class="nav0a">
          <el-button
            type="danger"
            size="mini"
            round
            @click="deleteMsg"
            :disabled="multipleSelection.length === 0"
          >删除</el-button>
        </h4>
        <el-table
          :data="message"
          @row-click="select"
          ref="messageForm"
          highlight-current-row
          :row-class-name="tableRowClassName"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="title" label="标题"></el-table-column>
          <el-table-column prop="genTime" label="时间" width="120"></el-table-column>
        </el-table>
        <div class="paginationwrap-g">
          <el-pagination
            :page-size="count"
            :current-page="page"
            :page-count="9"
            layout="total, prev, pager, next"
            :total="total"
            hide-on-single-page
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMessagePc, updateMessage, delMessage } from "@/api/api";
export default {
  data() {
    return {
      currentRow: {},
      multipleSelection: [],
      total: 0,
      count: 10,
      page: 1,
      messageLog: [],
      message: []
    };
  },
  mounted() {
    this.getMessageFn();
  },
  methods: {
    getMessageFn() {
      const that = this;
      getMessagePc({
        page: that.page,
        count: that.count
      }).then(res => {
        if (res.result) {
          that.message = res.data.list;
          that.message.map(item => {
            item.genTime = item.genTime.slice(0, 10);
          });
          that.total = res.data.total;
          if (that.message.length > 0) {
            that.select(that.message[0]);
          } else {
            that.currentRow = {};
          }
          that.setMsgCount();
        }
      });
    },
    updateMessageFn(id) {
      const that = this;
      updateMessage({
        message_id: [id]
      }).then(res => {
        if (res.result) {
          let i = that.message.findIndex(item => {
            return item.id == id;
          });
          that.message[i].state = 1;
          that.setMsgCount();
        }
      });
    },
    setMsgCount() {
      let unreads = this.message.filter(item => item.state == 0);
      let biz = this.$store.getters.getBiz;
      biz.msgCount = unreads.length;
      this.$store.dispatch("setBiz", biz);
      console.log(biz, "66666666666");
    },
    select(row) {
      this.updateMessageFn(row.id);
      this.currentRow = row;
      this.$refs.messageForm.setCurrentRow(row);
      if (row.state === "0") {
        row.state = "1";
        //messageLog操作
        var rsp = {};
        rsp.msgSn = row.msgSn;
        rsp.state = "1"; //后台messagelog中修改消息状态
        this.messageLog.push(rsp);
        this.messageLog = [];
      }
    },
    tableRowClassName({ row }) {
      // 未读消息粗黑
      if (row.state == "0") {
        return "notread";
      }
      return "";
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    deleteMsg() {
      const that = this;
      this.$confirm("确认删除选中的消息？", {
        type: "error"
      })
        .then(() => {
          let idArray = [];
          this.multipleSelection.forEach(element => {
            idArray.push(element.id);
          });
          delMessage({
            message_id: idArray
          }).then(res => {
            if (res.result) {
              that.messageLog = [];
              that.multipleSelection = [];
              that.$message({ message: "删除成功", type: "success" });
              that.getMessageFn();
            }
          });
        })
        .catch(() => {
          this.$message({ message: "取消删除", type: "warning" });
        });
    },
    deleteThis() {
      const that = this;
      this.$confirm("确认删除消息？", {
        type: "error"
      })
        .then(() => {
          delMessage({
            message_id: [that.currentRow.id]
          }).then(res => {
            if (res.result) {
              that.$message({ message: "删除成功", type: "success" });
              that.currentRow = {};
              that.getMessageFn();
            }
          });
        })
        .catch(() => {
          this.$message({ message: "取消删除", type: "warning" });
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getMessageFn()
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.container0a {
  display: table;
  width: 100%;
}
.container0a .right {
  width: 55%;
  display: table-cell;
  padding: 0px 40px;
  border-left: 1px dashed #aaa;
}
.container0a .left {
  width: 45%;
  display: table-cell;
  padding: 0px 40px;

  text-align: justify;
}
.el-table .notread {
  font-weight: 600;
}
.nav0a {
  display: block;
  text-align: right;
  padding: 5px 20px;
  border-bottom: 1px solid #999;
}
.title0a {
  border-bottom: 1px solid #999;
  display: flex;
  padding: 5px 0px;
  justify-content: space-between;
  align-items: baseline;
}
.cellscreen0a {
  box-sizing: border-box;
  padding: 0xp 20px;
}
.imgwrap {
  width: 375px;
  margin: 20px auto;
  box-sizing: border-box;
}
</style>